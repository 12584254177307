import { DebouncedFieldInput } from '@/components/DebouncedFieldInput';
import { DebouncedFieldTextArea } from '@/components/DebouncedFieldTextArea';
import {
  authStorage,
  keycloak,
  ROLE_LEVEL,
  ROLE_TYPE,
  SERVICE_LIST_ARRAY,
} from '@/config';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Card, Col, Row, Select, Spin } from 'antd';
import Button from 'antd/es/button';
import login_image from '@/assets/image_login_register.png';
import login_samsung from '@/assets/logo_samsung_login.png';
import React, { Fragment, useEffect, useState } from 'react';
import {
  FieldSelect,
  Form,
  FormAction,
  formActions,
  FormOnSubmit,
  useEventCallback,
  useForm,
  validateForm,
  validators,
} from 'swuif';
import ServiceFields from './ServiceFields';
import { motion } from 'framer-motion';
import { logout } from '@/routes/AuthLogin';
import LoadingPage from '@/components/LoadingPage';
import { openNotification } from '@/components/OpenNotification';
import { extractEmail } from '@/config/utils';
import { useHistory } from 'react-router-dom';
import { kcAdmin } from '@/clients/keycloakAdmin';
import { ReactComponent as WarningIcon } from '@/assets/ic_warning.svg';
import { sbaas_stcms_api } from '@/config/sbaas';
import { ReactComponent as SubmitIcon } from '@/assets/icon_submit_white.svg';

const SERVICE_LIST = SERVICE_LIST_ARRAY;

const RegistrationForm = () => {
  const history = useHistory();
  const isPreprod =
    process.env.REACT_APP_STCMS_PREPROD === 'true' ? true : false;
  const appEnv = process.env.REACT_APP_STCMS_ENV;
  const [isDetail, setIsDetail] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSpinner, setLoadingSpinner] = useState<boolean>(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(false);
  const [countries, setCountries] = useState([]);
  const [countriesMap, setCountriesMap] = useState({});
  const [serviceList, setServiceList] = useState<number[]>([
    new Date().getTime(),
  ]);
  const [availableService, setAvailableService] = useState<any[]>([]);
  const [selectContainer, setSelectContainer] = useState<HTMLElement>();
  const [userAdmin, setUserAdmin] = useState({});
  const [showBackButton, setShowBackButton] = useState<boolean>(false);

  const onSubmit: FormOnSubmit = async data => {
    setLoadingSpinner(true);

    const user: any = {};
    const kcUser: any = {};
    const attributes: any = {};

    let validation = await validateForm(form);
    let validRes = validation.results;
    let isValid = validation.valid;

    if (!data.country) {
      validRes['country'] = ['Country is required'];
      isValid = false;
    }
    if (!data.reason) {
      validRes['reason'] = ['Purpose of use is required'];
      isValid = false;
    }

    if (
      availableService.indexOf('explore') === -1 &&
      availableService.indexOf('sd') > -1
    ) {
      openNotification({
        message: `"SD" service must be included`,
        description: `Access to explore post detail requires "SD" service.
        If you only need the "Explore" service, please set the "SD" service role to "Global / Country Viewer".`,
        type: 'error',
      });
      isValid = false;
    }

    if (
      availableService.indexOf('explore_retail') === -1 &&
      availableService.indexOf('explore') > -1
    ) {
      openNotification({
        message: `"Explore (non-Retail)" service must be included`,
        description: `The Explore (Retail) menu is inside Explore menu.
        If you only need the "Explore (Retail)" service, please set the "Explore (non-Retail)" service role to "Global / Country Viewer".`,
        type: 'error',
      });
      isValid = false;
    }

    serviceList.forEach(key => {
      if (!data[`${key}_service_name`]) {
        openNotification({
          message: 'Request access role failed!',
          description: 'Service name is required',
          type: 'error',
        });
        isValid = false;
      }
      if (!data[`${key}_service_role`]) {
        openNotification({
          message: 'Request access role failed!',
          description: 'Role is required',
          type: 'error',
        });
        isValid = false;
      }
      if (
        data[`${key}_service_role`] &&
        (data[`${key}_service_role`] === 'COUNTRY_MANAGER' ||
          data[`${key}_service_role`] === 'COUNTRY_GENERAL_ADMIN' ||
          data[`${key}_service_role`] === 'COUNTRY_SERVICE_ADMIN' ||
          data[`${key}_service_role`] === 'COUNTRY_VIEWER')
      ) {
        if (!data[`${key}_for_global_country`]) {
          if (
            data[`${key}_countries`] &&
            data[`${key}_countries`].length === 0
          ) {
            openNotification({
              message: 'Request access role failed!',
              description: 'Country is required for Country Role',
              type: 'error',
            });
            isValid = false;
          }
        }
      }
      if (!data[`${key}_approver`]) {
        openNotification({
          message: 'Request access role failed!',
          description: 'Approver is required',
          type: 'error',
        });
        isValid = false;
      }
    });

    dispatch(formActions.setBulkValidationResults(validRes, false));

    if (isValid) {
      attributes['registration_status'] = 'PENDING';
      attributes['department'] = data.department;
      kcUser['attributes'] = attributes;

      user['username'] = data.username;
      user['fullname'] = data.fullname;
      user['user_id'] = data.username;
      user['department'] = data.department;
      user['origin-country'] = data.country;
      user['services'] = [];
      user['registration-reason'] = data.reason;

      for (let key of serviceList) {
        let service: any = {};
        service['key'] = data[`${key}_service_name`];
        service['allCountry'] = !!data[`${key}_for_global_country`];
        if (data[`${key}_countries`]) {
          service['countries'] = data[`${key}_countries`].map(
            (country: any) => country.code,
          );
        } else {
          service['countries'] = [];
        }
        service['role'] = data[`${key}_service_role`];
        service['approver'] = data[`${key}_approver`];
        service['cc_email'] = data[`${key}_cc_email`];
        service['note_email'] = data[`${key}_note_email`];
        user['services'].push(service);
      }

      kcAdmin.editRegisterUser(data.userId, kcUser).then(async () => {
        await sbaas_stcms_api.script('register-user').post({ body: user });
        openNotification({
          message: 'Registration success.',
          description:
            'Your registration is already submitted. Please wait until access granted.',
          type: 'success',
        });
        localStorage.setItem('firstLogin', 'true');
        logout();
      });
    } else {
      setLoadingSpinner(false);
    }
  };

  const form = useForm({ onSubmit, init: true });
  const [formState, dispatch] = form;

  const getCountry = async () => {
    try {
      const res = await sbaas_stcms_api.script('get_country_list').get();
      const country_list = res.data;
      const countries = {};
      country_list.forEach((c: any) => {
        countries[c.code] = c.name;
      });
      setCountries(country_list);
      setCountriesMap(countries);
    } catch (e) {
      openNotification({
        message: 'Failed to get country list.',
        type: 'error',
        description: `Error to get country list : ${e}`,
      });
    }
  };

  const onAddService = useEventCallback(() => {
    const key = new Date().getTime();
    setServiceList([...serviceList, key]);
  });

  const getUserAdmin = async () => {
    try {
      const res = await sbaas_stcms_api.script('get-user-admin').get();
      const adminData = res.data;
      let serviceAdmin = {};
      adminData.forEach((user: any) => {
        if (!serviceAdmin[user.key]) serviceAdmin[user.key] = {};
        if (user.role === 'COUNTRY_MANAGER') {
          if (!serviceAdmin[user.key]['COUNTRY_MANAGER'])
            serviceAdmin[user.key]['COUNTRY_MANAGER'] = [];
          serviceAdmin[user.key]['COUNTRY_MANAGER'].push({
            username: user.username,
            role: user.role,
            countries: user.countries,
          });
        } else if (user.role === 'GLOBAL_MANAGER') {
          if (!serviceAdmin[user.key]['GLOBAL_MANAGER'])
            serviceAdmin[user.key]['GLOBAL_MANAGER'] = [];
          serviceAdmin[user.key]['GLOBAL_MANAGER'].push({
            username: user.username,
            role: user.role,
            countries: user.countries,
          });
        } else if (user.role === 'OPERATION') {
          if (!serviceAdmin[user.key]['OPERATION'])
            serviceAdmin[user.key]['OPERATION'] = [];
          serviceAdmin[user.key]['OPERATION'].push({
            username: user.username,
            role: user.role,
            countries: user.countries,
          });
        }
      });
      setUserAdmin(serviceAdmin);
    } catch (e) {
      openNotification({
        message: 'Failed to get admin list.',
        type: 'error',
        description: `Error to get admin list : ${e}`,
      });
    }
  };

  const onDeleteService = useEventCallback((keyRef: number) => {
    function deleteKey(arr: number[], key: number) {
      const arrRet: number[] = [];
      arr.forEach(elm => {
        if (elm !== key) arrRet.push(elm);
      });
      return arrRet;
    }

    if (
      formState.data[`${keyRef}_service_name`] &&
      availableService.indexOf(formState.data[`${keyRef}_service_name`] === -1)
    ) {
      setAvailableService([
        ...availableService,
        formState.data[`${keyRef}_service_name`],
      ]);
    }

    setServiceList(deleteKey(serviceList, keyRef));

    const formDat: object = {};
    Object.keys(formState.data).forEach(k => {
      if (!k.includes(keyRef.toString())) formDat[k] = formState.data[k];
    });
    dispatch(formActions.reset(formDat));
  });

  const setApprover = (key: any, countries?: any) => {
    const data = formState.data;
    let found = false;
    let adminEmail = '';
    if (userAdmin[data[`${key}_service_name`]]) {
      const role = userAdmin[data[`${key}_service_name`]];
      if (
        role[ROLE_TYPE.COUNTRY_MANAGER] &&
        ROLE_LEVEL[ROLE_TYPE.COUNTRY_MANAGER] <=
          ROLE_LEVEL[data[`${key}_service_role`]] &&
        data[`${key}_countries`].length > 0
      ) {
        const users = role['COUNTRY_MANAGER'];
        Object.keys(users).forEach((user: any) => {
          const adminCountries = users[user].countries.split(',');
          const uCountries = countries ? countries : data[`${key}_countries`];
          const userCountries = uCountries.map((c: any) => c.code);
          const filteredCountry = userCountries.every((value: any) =>
            adminCountries.includes(value),
          );
          if (filteredCountry) {
            adminEmail = users[user].username;
            found = true;
            return;
          }
        });
      }
      if (!found && role['GLOBAL_MANAGER']) {
        const users = role['GLOBAL_MANAGER'];
        adminEmail = users[0].username;
        found = true;
      }
      if (!found && role['OPERATION']) {
        const users = role['OPERATION'];
        adminEmail = users[0].username;
        found = true;
      }
    }
    dispatch(formActions.setData(adminEmail, `${key}_approver`));
  };

  const resetApprover = (key: any) => {
    dispatch(formActions.setData('', `${key}_approver`));
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    if (windowWidth < 1080) {
      setShowBackButton(true);
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [windowWidth]);

  useEffect(() => {
    if (!isDetail) {
      authStorage.load().then(async result => {
        setLoadingSpinner(true);
        Promise.all([getUserAdmin(), getCountry()]).then(() =>
          setLoadingSpinner(false),
        );
        if (result) {
          setIsDetail(true);
          const initUser = {
            username: result.user.email,
            department: result.user.department,
            userId: result.user.userId,
            email: result.user.email,
            fullname: result.user.fullName,
          };
          dispatch(formActions.initData(initUser));
        } else {
          await keycloak.instance.login({
            redirectUri: window.location.origin + '/register',
            idpHint: 'samsung-corp',
          });
        }
        setLoading(false);
      });
    }
    // eslint-disable-next-line
  }, [isDetail]);

  useEffect(() => {
    const element = document.querySelector(
      '.registration-field-container',
    ) as HTMLElement;
    if (element) setSelectContainer(element);
    setAvailableService(SERVICE_LIST.map((service: any) => service.value));
    window.addEventListener('resize', handleResize, false);

    // Redirect if user already registered
    authStorage.load().then(async result => {
      setLoadingSpinner(true);
      if (result && result.user.registration_status === 'ACTIVE') {
        const registeredUser = await sbaas_stcms_api
          .script('get-registered-user')
          .put({
            query: {
              username: extractEmail(result.user.username),
              email: result.user.email,
            },
          });
        if (registeredUser && registeredUser.data.length > 0) {
          history.push('/');
        }
      }
      setLoading(false);
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <div
        style={{
          position: 'absolute',
          top: '100px',
          right: '50px',
          zIndex: 5,
          display: showBackButton ? 'block' : 'none',
        }}
        onClick={() => {
          let temp = {};
          temp['username'] = formState.data.email;
          temp['department'] = formState.data.department;
          temp['userId'] = formState.data.userId;
          temp['email'] = formState.data.email;
          temp['fullname'] = formState.data.fullName;
          let tempStr = JSON.stringify(temp);
          localStorage.setItem('temp', tempStr);
          localStorage.setItem('tempTrans', 'back');
          logout();
        }}
      >
        <img src={require('@/assets/icon_back_button.svg')} alt="" />
      </div>

      <div
        style={{
          overflow: 'hidden',
        }}
      >
        {loading ? (
          <LoadingPage />
        ) : (
          <div>
            {isPreprod && (
              <div
                style={{
                  width: '100%',
                  height: '30px',
                  background: '#FFF0A4',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <WarningIcon />
                This is a
                <span
                  style={{
                    margin: '0 5px',
                    width: '33px',
                    height: '18px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '3px',
                    background: '#0A186E',
                    color: '#FFF',
                    fontSize: '12px',
                  }}
                >
                  {appEnv}
                </span>
                version. Please visit
                <a
                  href="https://smartthings-cms.samsungiotcloud.com/"
                  style={{
                    margin: '0px 5px',
                    textDecoration: 'underline',
                    color: '#0A186E',
                    fontWeight: 700,
                  }}
                >
                  smartthings-cms.samsungiotcloud.com
                </a>
                to see the live version.
              </div>
            )}
            <div
              style={{
                width: '100%',
                height: isPreprod ? 'calc(100vh - 30px)' : '100vh',
              }}
            >
              <motion.div
                animate={{ x: 0 }}
                initial={{ x: isMobile ? 0 : windowWidth * -0.5 }}
                transition={{
                  delay: 1.5,
                  duration: 1.5,
                }}
                style={{
                  width: isMobile ? '100vw' : '150vw',
                  height: '100vh',
                  display: 'flex',
                }}
                onAnimationComplete={() => {
                  setShowBackButton(true);
                }}
              >
                <div
                  id="register-wrapper"
                  style={{
                    width: isMobile ? '100%' : '50%',
                    height: '100vh',
                    background: '#FFFFFF',
                    overflowY: 'scroll',
                    display: 'block',
                  }}
                >
                  <Row
                    justify="center"
                    align="middle"
                    style={{ height: '100%' }}
                  >
                    <Col style={{ padding: '50px 60px' }} span={24}>
                      <Card
                        bordered={true}
                        style={{
                          width: '100%',
                          borderRadius: '20px',
                          border: '1px solid #F5F5F5',
                        }}
                      >
                        <Spin spinning={loadingSpinner}>
                          <Row justify="center">
                            <Col
                              className="registration-field-container"
                              span={24}
                              style={{
                                paddingLeft: '40px',
                                paddingRight: '40px',
                              }}
                            >
                              <div className="register-form-title">
                                Register New Account
                              </div>

                              <div className="register-form-subtitle">
                                Tell Us Your Needs
                              </div>

                              <div
                                style={{
                                  border: '2px solid #C7C7C7',
                                  borderWidth: '0.3vh 0 0 0',
                                  marginTop: '0.8vh',
                                  marginBottom: '10px',
                                }}
                              ></div>

                              <Form store={form} layout="horizontal">
                                <div className="form-title-wrapper">
                                  <div
                                    className="form-title-text"
                                    style={{ fontSize: '16px' }}
                                  >
                                    Personal Information
                                  </div>
                                </div>

                                <Row gutter={16}>
                                  <Col span={8}>
                                    <DebouncedFieldInput
                                      grid={{ sm: 24, md: 24, lg: 24 }}
                                      labelCol={{ sm: 24, md: 24, lg: 24 }}
                                      wrapperCol={{ sm: 24, md: 24, lg: 24 }}
                                      id="username"
                                      label="Username"
                                      validator={validators.pattern(
                                        /^(?!.*;).*$/gm,
                                        '; character is not allowed',
                                      )}
                                      hasFeedback
                                      disabled
                                      required
                                    />
                                  </Col>

                                  <Col span={8}>
                                    <DebouncedFieldInput
                                      grid={{ sm: 24, md: 24, lg: 24 }}
                                      labelCol={{ sm: 24, md: 24, lg: 24 }}
                                      wrapperCol={{ sm: 24, md: 24, lg: 24 }}
                                      id="department"
                                      label="Department"
                                      validator={validators.pattern(
                                        /^(?!.*;).*$/gm,
                                        '; character is not allowed',
                                      )}
                                      hasFeedback
                                      disabled
                                      required
                                    />
                                  </Col>

                                  <Col span={8}>
                                    <FieldSelect
                                      grid={{ sm: 24, md: 24, lg: 24 }}
                                      labelCol={{ sm: 24, md: 24, lg: 24 }}
                                      wrapperCol={{ sm: 24, md: 24, lg: 24 }}
                                      id="country"
                                      label="Country"
                                      hasFeedback
                                      required
                                      placeholder="Your origin country"
                                      showSearch
                                      filterOption={(input, option: any) =>
                                        option.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                      getPopupContainer={trigger =>
                                        trigger.parentElement
                                      }
                                    >
                                      {countries.map((country: any) => (
                                        <Select.Option
                                          key={country.code}
                                          value={country.code}
                                        >
                                          {`${country.name} (${country.code})`}
                                        </Select.Option>
                                      ))}
                                    </FieldSelect>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col span={24}>
                                    <DebouncedFieldTextArea
                                      grid={{ sm: 24, md: 24, lg: 24 }}
                                      labelCol={{ sm: 24, md: 24, lg: 24 }}
                                      wrapperCol={{ sm: 24, md: 24, lg: 24 }}
                                      rows={4}
                                      id="reason"
                                      label="Purpose of use"
                                      validator={validators.pattern(
                                        /^(?!.*;).*$/gm,
                                        '; character is not allowed',
                                      )}
                                      hasFeedback
                                      required
                                      placeholder="I want to manage GSE content for Korea"
                                    />
                                  </Col>
                                </Row>

                                <div
                                  style={{
                                    border: '2px solid #C7C7C7',
                                    borderWidth: '0.3vh 0 0 0',
                                    marginTop: '0.8vh',
                                    marginBottom: '10px',
                                  }}
                                ></div>

                                <div className="form-title-wrapper">
                                  <div
                                    className="form-title-text"
                                    style={{ fontSize: '16px' }}
                                  >
                                    Service
                                  </div>
                                </div>

                                <Row>
                                  <Col span={24}>
                                    {serviceList.map(
                                      (keyRef: any, index: any) => (
                                        <ServiceFields
                                          key={keyRef}
                                          id={index}
                                          keyRef={keyRef}
                                          dispatch={dispatch}
                                          onDeleteService={onDeleteService}
                                          countryList={countries}
                                          countryMap={countriesMap}
                                          container={selectContainer}
                                          availableService={availableService}
                                          setAvailableService={
                                            setAvailableService
                                          }
                                          data={
                                            formState && formState.data
                                              ? formState.data
                                              : {}
                                          }
                                          serviceAdmin={userAdmin}
                                          setApprover={setApprover}
                                          resetApprover={resetApprover}
                                        />
                                      ),
                                    )}
                                  </Col>
                                </Row>

                                {availableService.length > 0 &&
                                  serviceList.length < SERVICE_LIST.length && (
                                    <Row style={{ display: 'block' }}>
                                      <Col span={24}>
                                        <div
                                          style={{
                                            cursor: 'pointer',
                                          }}
                                          onClick={onAddService}
                                        >
                                          <Button
                                            style={{ width: '32px' }}
                                            className="button-secondary add-service-role-button"
                                          >
                                            <PlusOutlined />
                                          </Button>
                                          <span className="add-service-role-text">
                                            Add Service Role
                                          </span>
                                        </div>
                                      </Col>
                                    </Row>
                                  )}

                                <Row>
                                  <FormAction noActions>
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <Button
                                        className="button-primary"
                                        type="primary"
                                        onClick={() =>
                                          dispatch(formActions.submit())
                                        }
                                        style={{
                                          marginRight: '10px',
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <SubmitIcon
                                          style={{
                                            width: '20px',
                                            marginRight: '10px',
                                          }}
                                        />
                                        Register
                                      </Button>
                                      <Button
                                        onClick={() => logout()}
                                        className="button-secondary"
                                      >
                                        <CloseOutlined />
                                        Cancel
                                      </Button>
                                    </div>
                                  </FormAction>
                                </Row>
                              </Form>
                            </Col>
                          </Row>
                        </Spin>
                      </Card>
                    </Col>
                  </Row>
                </div>

                <div
                  style={{
                    width: isMobile ? '0' : '50%',
                    height: '100%',
                    background:
                      'linear-gradient(to right, #fbfbfb 0%, #fbfbfb 50%, #fff 50%, #fff 100%)',
                    display: isMobile ? 'none' : 'block',
                  }}
                >
                  <motion.div
                    style={{
                      height: '100%',
                      width: '100%',
                      position: 'relative',
                      backgroundImage: `url(${login_image})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: '100% 100%',
                      backgroundPosition: 'center',
                    }}
                    transition={{
                      delay: 1.5,
                      duration: 1.5,
                      ease: 'linear',
                    }}
                  >
                    <img
                      alt=""
                      src={login_samsung}
                      style={{
                        position: 'absolute',
                        top: '40px',
                        left: '60px',
                        width: '160px',
                        height: '42px',
                      }}
                    ></img>
                  </motion.div>
                </div>

                <div
                  style={{
                    width: isMobile ? '0' : '50%',
                    height: '100%',
                    background: '#fff',
                    display: isMobile ? 'none' : 'block',
                  }}
                >
                  <Row
                    justify="center"
                    align="middle"
                    style={{ height: '100%', padding: '30px' }}
                  >
                    <Col>
                      <Row>
                        <span className="st-text">SmartThings</span>
                      </Row>
                      <Row>
                        <span className="cms-text">
                          Content Management System
                        </span>
                      </Row>
                      <Row style={{ display: 'flex', alignItems: 'center' }}>
                        <Col span={14} className="login-form-subtitle">
                          Let's get started!
                        </Col>
                        <Col span={10} className="login-form-version">
                          v.
                          {process.env.REACT_APP_VERSION
                            ? process.env.REACT_APP_VERSION
                            : '2.0.0-0'}
                        </Col>
                      </Row>

                      <div
                        style={{
                          border: '2px solid #C9C9C9',
                          borderWidth: '0.3vh 0 0 0',
                          marginTop: '1vh',
                          marginBottom: '6vh',
                          width: '100%',
                        }}
                      />

                      {/* Button for Continue with Corp AD */}
                      <Row justify="center" style={{ marginBottom: '30px' }}>
                        <Col span={24}>
                          <Button
                            className="button-primary"
                            style={{
                              height: '60px',
                              borderRadius: '8px !important',
                              width: '420px',
                            }}
                            block
                          >
                            <span className="login-button-text">
                              Continue with Corp AD
                            </span>
                          </Button>
                        </Col>
                      </Row>

                      {/* Button for Continue with SmartThings Okta */}
                      <Row justify="center" style={{ marginTop: '30px' }}>
                        <Col span={24}>
                          <Button
                            className="button-outline-primary"
                            style={{
                              height: '60px',
                              borderRadius: '8px !important',
                              width: '420px',
                            }}
                            block
                          >
                            <span className="login-button-text-outline">
                              Continue with SmartThings Okta
                            </span>
                          </Button>
                        </Col>
                      </Row>

                      {/* Button for Download User Registration Guide */}
                      <Row justify="center">
                        <Col>
                          <a
                            href={
                              window.location.origin + '/landing/index.html'
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            className="login-registration-guide"
                          >
                            User Guide
                          </a>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <div
                    style={{
                      width: '100%',
                      position: 'relative',
                      bottom: '30px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <div className="login-footer">
                      Samsung R&D Institute Indonesia
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default RegistrationForm;
